import {useEffect, useState} from "react";
import callApi from "../util/api";
import {useDispatch, useSelector} from "react-redux";
import {Calendar, momentLocalizer} from 'react-big-calendar';
import moment from 'moment';
import {useAddNotification} from "../state/hooks/notifications";

const localizer = momentLocalizer(moment)

const FirstAidEnrollment = () => {
    const addNotification = useAddNotification();
    const userLoggedIn = useSelector((state) => {
        return state.user.loggedIn
    });

    const [faEnrollments, setFaEnrollments] = useState([]);
    const dispatch = useDispatch();
    const onNavigate = async (date) => {
        await getAndSetEnrollments(dispatch, setFaEnrollments, date);
    };
    useEffect(async () => {
        try {
            await getAndSetEnrollments(dispatch, setFaEnrollments, new Date());
        } catch (err) {
            addNotification({
                id: 'faEnrollments',
                message: "There was some problem while trying to get first aid course enrollments, please try again."
            });
        }
    }, [userLoggedIn]);

    if (!userLoggedIn) return null;
    return <div><Calendar
        localizer={localizer}
        events={faEnrollments}
        startAccessor="start"
        endAccessor="end"
        onNavigate={onNavigate}
        style={{height: 500}}
    /></div>;
};

export default FirstAidEnrollment;

async function getAndSetEnrollments(dispatch, setFaEnrollments, date) {
    const enrollmentEvents = [];
    let enrollments = {};
    try {
        enrollments = await getEnrollments(dispatch, date);
    } catch (err) {
        console.error("error getting first aid enrollments", err);
    }

    Object.keys(enrollments)
        .filter(key => key.startsWith("date_"))
        .forEach(enrollmentDate => {
            const day = enrollmentDate.substring("date_".length);
            const eventDateStart = new Date(date.getFullYear(), date.getMonth(), parseInt(day));
            const eventDateEnd = new Date(date.getFullYear(), date.getMonth(), parseInt(day) + 1);
            (enrollments[enrollmentDate] || []).forEach(enrollment => {
                enrollmentEvents.push({
                    title: enrollment,
                    allDay: true,
                    start: eventDateStart,
                    end: eventDateEnd
                });
            });
        });
    setFaEnrollments(enrollmentEvents);
}

const getEnrollments = async (dispatch, now) => {
    return await callApi(dispatch,
        "get",
        `/ece/admin/fa/enrollments?month=${now.getMonth()}&year=${now.getFullYear()}`);
}