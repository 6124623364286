import PhoneInTalkRoundedIcon from "@mui/icons-material/PhoneInTalkRounded";
import AlternateEmailRoundedIcon from "@mui/icons-material/AlternateEmailRounded";

const Footer = () => {
  const currentYear = new Date().getFullYear();
  return <footer
    className="fixed inset-x-0 flex-wrap bottom-0 p-2 shadow flex items-center z-10 md:p-2 border-2 bg-gray-200">
    <ul className="flex space-x-2 items-center flex-wrap text-sm text-gray-500">
      <li>
        <a href="mailto:support@learnece.online"
           className="hover:underline font-medium text-blue-700 inline-flex items-center">
          <AlternateEmailRoundedIcon className="fill-indigo-400 w-8 ml-2"/>support@learnece.online</a>
      </li>
      <li>
        <a href="tel:7782280880"
           className="hover:underline font-medium text-blue-700 inline-flex items-center">
          <PhoneInTalkRoundedIcon className="fill-indigo-400 w-6 mr-1"/>778 300 6341</a>
      </li>
      <li>
        <a href="https://wa.link/4qdykk"
           className="hover:underline font-medium text-blue-700 inline-flex items-center">
          <img src="static/img/whatsapp-logo.webp" className="w-8"/>
          WhatsApp<span className="font-bold text-sm ml-1 text-gray-500">(faster response)</span>
        </a>
      </li>
    </ul>
    <ul className="sm:mx-auto flex space-x-2 items-center flex-wrap text-sm text-gray-500">
      <li>
        <span className="text-sm text-blue-800 ">© {currentYear + " "}
          <a href="/" className="hover:underline mr-1 font-medium tracking-wide">Learn ECE™</a>
        </span>
      </li>
    </ul>
    <ul className="flex space-x-2 items-center flex-wrap text-sm text-gray-500">
      <li>
        <a href="/privacy.html" className="mr-4 hover:underline md:mr-6 text-blue-700">Privacy Policy</a>
      </li>
    </ul>
  </footer>;
};

export default Footer;
