import {Button, Typography} from "@mui/material";
import * as React from "react";
import PictureAsPdfRoundedIcon from "@mui/icons-material/PictureAsPdfRounded";
import HearingRoundedIcon from "@mui/icons-material/HearingRounded";
import VerifiedUserRoundedIcon from "@mui/icons-material/VerifiedUserRounded";
import {Link} from "react-router-dom";
import PendingIcon from "@mui/icons-material/Pending";
import DownloadIcon from "@mui/icons-material/Download";
import {getLocationByCode} from "../util/FirstAidLocation";
import FirstAidOverview from "./FirstAidCourseOverview";

const CourseCardEnrolled = ({
                              course,
                              quizDate,
                              quizPassed,
                              quizCertificateUrl,
                            }) => {
  const oneYearInPast = new Date(new Date().setFullYear(new Date().getFullYear() - 1));
  quizDate = quizDate || 0;
  const quizExpired = quizDate < oneYearInPast.getTime();
  const isQuizPassed = !quizExpired && quizPassed;
  const isFirstAidCourse = course.id === "FA" || course.id === "FAB";

  return <div
    className="flex flex-col max-w-xs sm:max-w-md justify-center mx-auto mt-10 mb-10 rounded-lg shadow-md bg-indigo-100 border-gray-700">
    <img
      className="border-none max-w-xs sm:max-w-md place-self-center rounded-t rounded-l object-cover"
      src={"/static/img/courses/" + course.icon} alt="product image"/>
    <div className="flex flex-col justify-between my-auto px-3">
      <div className="flex flex-col py-3">
							<span className="font-semibold tracking-tight text-slate-700 break-normal pb-2">
								{course.name}
							</span>
        {!isFirstAidCourse &&
          <span className="text-base tracking-tight text-slate-600 break-normal">{course.description}</span>}
        {isFirstAidCourse && <FirstAidOverview/>}
      </div>
      <div>
      </div>
      <div className="flex mt-4 flex-wrap gap-2 items-center py-3">
        {(!isFirstAidCourse) && <>
          <>
            <Button startIcon={<PictureAsPdfRoundedIcon className="text-lg"/>}
                    className="text-sm font-sans px-3 text-indigo-700"
                    variant="text"
                    href={course.pdf}
                    target="_blank"
                    component="a"
            >
              PDF
            </Button>
            <Button startIcon={<HearingRoundedIcon className="text-lg"/>}
                    className="text-sm font-sans px-3 text-indigo-700"
                    variant="text"
                    href={course.audio}
                    target="_blank"
                    component="a"
            >
              Audio
            </Button>
            {!isQuizPassed &&
              <Button startIcon={<VerifiedUserRoundedIcon className="text-lg"/>}
                      variant="contained"
                      className="text-sm font-sans px-3"
                      color="primary" onClick={() => ({})}>
                <Link to={"/quiz/" + course.id}
                      style={{textDecoration: 'none'}}
                      href="#">
                  Quiz
                </Link>
              </Button>}
            {isQuizPassed && !quizCertificateUrl &&
              <Button startIcon={<PendingIcon className="text-lg"/>}
                      variant="contained"
                      disabled
                      className="text-sm font-sans px-3"
                      color="primary" onClick={() => ({})}>
                <Link to={"/quiz/" + course.id}
                      style={{textDecoration: 'none'}}
                      disabled
                      href="#">
                  Preparing Certificate
                </Link>
              </Button>}
            {isQuizPassed && quizCertificateUrl &&
              <Button startIcon={<DownloadIcon className="text-lg"/>}
                      variant="contained"
                      color="primary"
                      href={quizCertificateUrl}
                      target="_blank"
                      className="text-sm font-sans px-3"
                      component="a"
              >
                Download Certificate
              </Button>
            }
          </>
        </>}
        {(isFirstAidCourse) && <>
          <p><Typography><b>{new Date(course.d).toDateString()}</b> located
            at <b>{getLocationByCode(course.l)}</b></Typography>
          </p>
          <p>
            <Typography>Starts at <b>9 AM</b></Typography></p>
        </>
        }
      </div>
    </div>
  </div>
};

export default CourseCardEnrolled;
