import {useDispatch, useSelector} from "react-redux";
import CartItems from "../comp/CartItems";
import {ClearCart, RemoveCourse, SetCartToken} from "../state/Cart";
import {useNavigate} from "react-router-dom";
import {AddEnrolled} from "../state/UserState";
import callApi from "../util/api";
import {useAddNotification} from "../state/hooks/notifications";
import {Backdrop, CircularProgress} from "@mui/material";
import AvailableCourses from "../state/AvailableCourses";
import {getLocationByCode} from "../util/FirstAidLocation";

const Cart = () => {
  // Promotion flag
  const isPromotion = false;

  const dispatch = useDispatch();
  const addNotification = useAddNotification()
  const navigate = useNavigate();
  const enrolled = useSelector(state => {
    return state.user && state.user.details && state.user.details.enrolled ? state.user.details.enrolled : [];
  });
  const userLoggedIn = useSelector((state) => {
    return state.user.loggedIn
  });
  let token = useSelector((state) => state.cart.token);
  const enrolledMap = {};
  enrolled.forEach(course => enrolledMap[course.id] = course);

  const coursesInCart = useSelector(state => state.cart.courses);
  const coursesArrayInCart = Object.values(coursesInCart);
  const removeFromCart = (courseId) => () => {
    dispatch(RemoveCourse({id: courseId}));
  }
  const apiLoading = useSelector(state => state.api.loading);
  const selectedFirstAidCourseDate = useSelector(state => state.cart.firstAidCourseDate);
  const selectedFirstAidCourseLocation = useSelector(state => state.cart.firstAidCourseLocation);

  const clearCart = () => dispatch(ClearCart());

  const availableCourses = AvailableCourses;
  const paymentSubmitHandler = async (nonce) => {
    const isBundlePurchase = coursesArrayInCart.filter(c => c.id === "FAB" || c.id === "BUN").length === 1;
    const coursesToEnrollAsPartOfBundle = isBundlePurchase
      ? availableCourses
        .filter(c => c.id !== "FAB" && c.id !== "FA" && c.id !== "BUN")
        .filter(c => !enrolledMap[c.id])
        .map(c => c.id)
      : [];
    callApi(dispatch, "post", "/ece/cart", {
      isPromotion: isPromotion,
      nonce,
      courseIds: coursesArrayInCart.map(c => c.id),
      firstAidCourseDate: coursesArrayInCart.filter(c => c.id === "FA" || c.id === "FAB").length ? selectedFirstAidCourseDate : null,
      firstAidCourseLocationCode: selectedFirstAidCourseLocation,
      firstAidCourseLocation: getLocationByCode(selectedFirstAidCourseLocation),
      bundleCourseIds: coursesToEnrollAsPartOfBundle
    })
      .then(({enrolled, token}) => {
        clearCart();
        dispatch(AddEnrolled({enrolled}));
        dispatch(SetCartToken({token: token.clientToken}));
        navigate("/mycourses");
      }).catch(err => {
      console.log('error checking out', err);
      addNotification({id: 'cart', message: "There was a problem processing your purchase, please try again."});
    });
  }

  return <>
    <CartItems
      courses={coursesArrayInCart}
      userLoggedIn={userLoggedIn}
      enrolled={enrolledMap}
      removeFromCartHandler={removeFromCart}
      token={token}
      paymentSubmitHandler={paymentSubmitHandler}
      selectedFirstAidCourseDate={selectedFirstAidCourseDate}
      selectedFirstAidCourseLocation={getLocationByCode(selectedFirstAidCourseLocation)}
    />
    <Backdrop
      sx={{color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1}}
      open={apiLoading}
    >
      <CircularProgress color="inherit"/>
    </Backdrop>
  </>;
};

export default Cart;
