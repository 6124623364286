import {Alert, Snackbar} from "@mui/material";
import React, {useState} from "react";

const NotificationToast = ({message}) => {
  const [show, setShow] = useState(true);
  const hideSnack = () => setShow(false);
  return <div className="shadow-2xl rounded shadow-black">
    <Snackbar autoHideDuration={6000}
              open={show}
              onClose={hideSnack}
              anchorOrigin={{vertical: 'top', horizontal: 'center'}}
              key={"alert_"}>
      <Alert color="info" sx={{width: "100%", alignItems: "bottom", justifyContent: "center", backgroundColor: "#FFA500"}}
             icon={false}>
        <span className="text-lg font-bold">{message}</span>
      </Alert>
    </Snackbar>
  </div>;
}

export default NotificationToast;
