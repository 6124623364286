import awsConfig from '../aws-exports';
import prodAwsConfig from '../aws-exports-prod';
import {Amplify, Auth} from "aws-amplify";

const isProd = process.env.REACT_APP_ENV_TYPE === 'production';
const awsConfigToMerge = isProd ? prodAwsConfig : awsConfig;
const updatedAwsConfig = {
    ...awsConfigToMerge,
    oauth: {
        ...awsConfigToMerge.oauth,
        redirectSignIn: awsConfigToMerge.oauth.redirectSignIn,
        redirectSignOut: awsConfigToMerge.oauth.redirectSignOut,
    }
}

Amplify.Logger.LOG_LEVEL = 'ERROR';
Amplify.configure(updatedAwsConfig);
