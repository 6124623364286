const CreateCourse = (id, name, description, icon, price, hours) => ({
  id,
  name,
  description,
  price,
  hours,
  icon
});
export default [
  CreateCourse("FAB", "First Aid Training (In Person) & ECE courses bundle",
    "Our First Aid course meets the provincial child care licensing requirements (Schedule C). There are no prerequisites. The course certificate is valid for 3 years.",
    "red_cross_training.jpg",
    115,
    40
  ), CreateCourse("FA", "First Aid Training (In Person)",
    "Our First Aid course meets the provincial child care licensing requirements (Schedule C). There are no prerequisites. The course certificate is valid for 3 years.",
    "red_cross_training.jpg",
    100,
    8
  ),
  CreateCourse("BUN", "Pro-D Courses Bundle",
    "All Pro-D courses in one affordable price",
    "course_bundle.png",
    50,
    32
  ),
  CreateCourse("c1", "Advocating for play: The benefits of play",
    "Identify different kinds and stages of plays",
    "benefits_play.jpg",
    10,
    2.5
  ),
  CreateCourse("c2", "How infants & toddlers learn",
    "Essential elements of quality infant and toddler programs",
    "it_learn.jpg",
    10,
    2.5
  ),
  CreateCourse("c3", "Helping young children develop healthy eating habits",
    "How are early eating behaviors influenced by teachers/caregivers?",
    "healthy_eating.jpg",
    10,
    2.5
  ),
  CreateCourse("c4", "Helping young children develop a positive sense of self",
    "Identify concepts related to a positive sense of self in young children",
    "positive_self.jpg",
    10,
    2
  ),
  CreateCourse("c5", "Building Parent-Teacher relationships",
    "Describe how to utilize strategies to build trusting relationships with families",
    "parent_teacher.jpg",
    10,
    2.5
  ),
  CreateCourse("c6", "Is screen time affecting children's brains?",
    "Why screen time should be limited",
    "screen_time.jpg",
    10,
    2
  ),
  CreateCourse("c7", "Children developmental and learning theories",
    "What does development psychology study",
    "dev_learn_theory.jpg",
    10,
    2
  ),
  CreateCourse("c8", "Understanding challenging behaviours and building social emotional skills in infants, toddlers, and preschoolers",
    "Understanding how a child's brain develops can help you better understand their behaviours",
    "challenge_behavior.jpg",
    10,
    3
  ),
  CreateCourse("c9", "Strengthening engagement of Asian families",
    "Identify the vast diversity of families that might be categorized as “Asian” and the need to learn about families’ specific backgrounds",
    "asian.jpg",
    10,
    2
  ),
  CreateCourse("c10", "Providing support to ASD student in a preschool classroom",
    "Identify common behaviours of ASD students in the classroom; Identify common ASD Myths and Misconceptions;",
    "provide_support_asd.jpg",
    10,
    2
  ),
  CreateCourse("c11", "What Is Executive function? Why is it important in Early Childhood Education?",
    "What Is Executive Function? How can you support children's executive functioning in early childhood?",
    "executive_function.jpg",
    10,
    2
  ),
  CreateCourse("c12", "Understanding, preventing, and responding effectively to challenging behaviour in young children",
    "Identify how to adapt to cope with behaviors that feel particularly challenging; What is SEL and how can it assist to prevent challenging behaviour;",
    "challenging_behaviours.jpg",
    10,
    5
  ),
  CreateCourse("c13", "Why is early childhood music education so important?",
    "Describe how music enhances children's development in all areas; describe how to lead interactive music & movement groups with young children",
    "music_education.jpg",
    10,
    2
  ),
  CreateCourse("c14", "Positively saying 'No'",
    "The importance of saying 'No'; Teach children what “No” means; How to strike a balance",
    "saying_no.jpg",
    10,
    2
  ),
  CreateCourse("c15", "Developing empathy",
    "Developing empathy to promote equity in early childhood settings",
    "develop_empathy.jpg",
    0,
    2
  )
];
