import {Box, Button, Grid, TextField,} from "@mui/material";
import * as React from 'react';
import {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {AddCourse, RemoveCourse, SetFirstAidCourseDate, SetFirstAidCourseLocation} from '../state/Cart';
import {useNavigate} from "react-router-dom";
import ShoppingCartTwoToneIcon from "@mui/icons-material/ShoppingCartTwoTone";
import useSearch from "../util/search";
import SearchIcon from '@mui/icons-material/Search';
import CourseCardBuy from "../comp/CourseCardBuy";

const Courses = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const navigateToCart = () => navigate("/cart");
  const [coursesToDisplay, setCoursesToDisplay] = useState([]);
  const selectedFirstAidCourseDate = useSelector(state => state.cart.firstAidCourseDate);
  const selectedFirstAidCourseLocation = useSelector(state => state.cart.firstAidCourseLocation);

  const setSelectedFirstAidCourseDate = (date) => {
    dispatch(SetFirstAidCourseDate({date}));
  };
  const setSelectedFirstAidCourseLocation = (location) => {
    dispatch(SetFirstAidCourseLocation({location}))
  };

  let available = useSelector(state => {
    return state.courses.available
  });
  const enrolled = useSelector(state => {
    return state.user && state.user.details && state.user.details.enrolled ? state.user.details.enrolled : [];
  });

  const enrolledMap = {};
  enrolled.forEach(course => enrolledMap[course.id] = course);
  let availableCourses = available.filter(course => !enrolledMap[course.id]);
  let availableCoursesMap = {};
  availableCourses.forEach(course => availableCoursesMap[course.id] = course);

  useEffect(() => {
    setCoursesToDisplay(availableCourses);
  }, [enrolled.length]);


  const coursesInCart = useSelector(state => state.cart.courses);
  const courseIsAddedToCart = (course) => !!coursesInCart[course.id];
  const addRemoveCardClickHandler = (course) => () => {
    if (courseIsAddedToCart(course)) {
      dispatch(RemoveCourse(course))
    } else {
      dispatch(AddCourse(course))
    }
  };
  const totalHours = Object.values(coursesInCart).reduce((acc, course) => course.hours + acc, 0);
  const totalPrice = Object.values(coursesInCart).reduce((acc, course) => course.price + acc, 0);

  const searchFunction = useSearch();
  const searchHandler = (e) => {
    const searchText = e.target.value.trim();
    if (!searchText && coursesToDisplay) {
      setCoursesToDisplay(availableCourses);
      return;
    }

    let filteredCourses = searchFunction(searchText)
      .map(searchResult => {
        return searchResult.item.id
      })
      .map(id => availableCoursesMap[id])
      .filter(course => course);
    setCoursesToDisplay(filteredCourses);
  }

  const courses = coursesToDisplay.map((course, index) => {
    return CourseCardBuy({
      course,
      courseIsAddedToCart: courseIsAddedToCart(course),
      addRemoveFromCartHandler: addRemoveCardClickHandler(course),
      firstAidCourseDate: selectedFirstAidCourseDate,
      firstAidCourseLocation: selectedFirstAidCourseLocation,
      setFirstAidCourseDate: setSelectedFirstAidCourseDate,
      setFirstAidCourseLocation: setSelectedFirstAidCourseLocation
    })
  });
  const showBottomNav = totalPrice !== 0 && totalHours !== 0;

  return <>
    <Grid columnSpacing={0.5} marginTop={2} marginBottom={10} item container spacing={4} flexDirection="column">
      <Box flexDirection="row" sx={{textAlign: "center"}}>
        <SearchIcon sx={{mr: 1, my: 3.0}}/>
        <TextField id="search"
                   label="Search for courses"
                   variant="standard"
                   className="w-2/3 md:w-1/2"
                   onChange={searchHandler}
        />
      </Box>
      {courses}

      {!courses.length && <div style={{textAlign: "center", width: "100%"}}>
        <Button
          variant="contained"
          color="primary"
          fullWidth={false}
          style={{textAlign: "center", margin: "30px"}}
          onClick={() => navigate("/mycourses")}>
          Browse your enrolled courses
        </Button>
      </div>
      }
    </Grid>
    {showBottomNav &&
      <>
        <button
          className="fixed flex flex-col items-center z-90 bottom-32 right-4 h-22 w-22 rounded-2xl drop-shadow-lg hover:drop-shadow-2xl hover:animate-bounce duration-300 font-bold rounded-md m-auto bg-purple-700 px-5 py-1 text-gray-200 drop-shadow-lg"
          onClick={navigateToCart}
        >
          <ShoppingCartTwoToneIcon fontSize="small"/>
          <span className="text-sm"> {totalHours} Hrs</span>
          <span className="text-sm"> $ {totalPrice}</span>
        </button>
      </>
    }
  </>
}

export default Courses;
