import {useEffect, useState} from "react";
import {Button} from "@mui/material";

const BrainTree = ({token, paymentSubmitHandler, userLoggedIn}) => {
    const [btInitialized, setBtInitialized] = useState(false);

    if (!userLoggedIn) return;
    useEffect(() => {
        if (!token) return;

        braintree.dropin.create({
            authorization: token,
            selector: '#dropin-container'
        }, function (err, instance) {
            if (err) {
                console.error("error submitting payment", err);
                return;
            }
            setBtInitialized(true);
            const button = document.querySelector('#submit-button');
            button.addEventListener('click', function () {
                instance.requestPaymentMethod(async function (err, payload) {
                    await paymentSubmitHandler(payload.nonce);
                });
            })
        });
    }, [token]);
    return <>
        <div id="dropin-container"></div>
      <Button id="submit-button" disabled={!btInitialized}
              className="bg-indigo-800 hover:bg-indigo-600 text-gray-100"
              color="primary"
              variant="contained">Purchase</Button>
    </>;
};

export default BrainTree;
