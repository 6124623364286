import React, {Suspense, useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import {useFieldArray, useForm} from "react-hook-form";
import {useNavigate} from "react-router-dom";
import {MultipleChoice, TrueFalse} from "../state/quiz/Generator";
import {SetQuizResult, WipeOutSection2} from '../state/UserState';
import {useAddNotification} from "../state/hooks/notifications";

import {
  Box,
  Button,
  Checkbox,
  FormControlLabel, FormGroup,
  FormLabel, Grid,
  Radio,
  RadioGroup,
  Stack,
  Typography
} from "@mui/material";
import {useDispatch, useSelector} from "react-redux";
import callApi from "../util/api";
import UserProfileDialog from "./UserProfileDialog";
import NotificationToast from "./NotificationToast";
import useRefreshUserDetailsWhenPendingCertificate from "../hooks/useRefreshUserDetailsForPendingCertificate";

const Quiz = () => {
  const userLoggedIn = useSelector((state) => {
    return state.user.loggedIn
  });
  let availableCourses = useSelector(state => {
    return state.courses.available
  });

  const {id} = useParams();
  const matchingCourse = availableCourses.filter(c => c.id === id);

  const navigate = useNavigate();
  if (!userLoggedIn) {
    navigate("/mycourses");
  }

  const {register, control, handleSubmit, formState: {errors}} = useForm({
    defaultValues: {
      quiz: []
    }
  });

  const {
    fields,
    append,
    replace
  } = useFieldArray({
    control,
    name: "quiz"
  });

  const [quiz, SetQuiz] = useState({
    state: 'loading'
  });

  const dispatch = useDispatch();
  const addNotification = useAddNotification();
  const refreshUserIfNeeded = useRefreshUserDetailsWhenPendingCertificate({courseId:id});

  const onSubmit = (userSubmitted) => {
    // console.log('submitting', userSubmitted.quiz);
    let answers = {};
    for (let index = 0; index < userSubmitted.quiz.length; index++) {
      let questionId = userSubmitted.quiz[index].qid;
      answers[questionId] = userSubmitted.quiz[questionId];
    }
    callApi(dispatch, 'post', `/ece/course/${id}/quiz`, answers)
      .then((result) => {
        // console.log('submitted quiz', result)
        dispatch(SetQuizResult({result: result.passed, courseId: id}))
        addNotification({
          id: "quiz_" + id,
          message: result.passed ? `You passed the quiz for course ${id}` : `You did not pass the quiz for course ${id}, please re-attempt.`
        });
        if (result.passed) {
          dispatch(WipeOutSection2());
          refreshUserIfNeeded().then(() => {});
        }
        navigate("/mycourses");
      }).catch((err) => {
      console.error('error submitting quiz', err);
      addNotification({
        id: "quiz",
        message: "There was some network problem while submitting your quiz, please try again."
      })
    });
  }

  useEffect(async () => {
    try {
      const quiz = await loadQuiz(id);
      SetQuiz({state: 'loaded', questions: quiz.default});
      replace([]);
      quiz.forEach((question) => append(question));
    } catch (err) {
      SetQuiz({state: 'error'});
      console.error(err);
    }
  }, []);

  let toRender = () => <Box>
    <Stack direction="column" spacing={2} alignItems="center" className="mb-10">
      {errors && errors.quiz &&
        <NotificationToast message="Please answer all questions"/>
      }
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="mx-auto w-3/4">
          <Typography sx={{m: 2}} variant="h4">Quiz
            - {matchingCourse && matchingCourse.length ? matchingCourse[0].name : id}</Typography>
          <Suspense fallback={<div>Loading...</div>}>
            {fields.map((item, index) => {
              const questionErrors = errors['quiz'] ? errors['quiz'][item.id] : [];
              return (<RenderQuestion question={item} register={register} hasError={questionErrors}/>
              );
            })}
            <FormGroup>
              <Button type="submit" variant="contained" color="secondary"
                      sx={{m: 2, maxWidth: "50%", width: "30%", mx: "auto"}}>
                <span className="text-lg">Submit</span>
              </Button>
            </FormGroup>
          </Suspense>
        </div>
      </form>
    </Stack>
    <UserProfileDialog/>
  </Box>;
  switch (quiz.state) {
    case "loading": {
      return toRender("loading");
    }
    case "loaded": {
      return toRender("loaded");
    }
    case "error": {
      return toRender("Error");
    }
  }
};

export default Quiz;

const RenderQuestion = ({question, register, hasError}) => {
  switch (question.type) {
    case MultipleChoice: {
      return <FormGroup key={question.id} sx={{mb: 2}}>
        <FormLabel id={`${question.id}`} className="mb-2">
          <span className="text-2xl text-slate-700">{question.qid}. {question.question}</span>
        </FormLabel>
        {
          question.choices.map((choice) => <FormControlLabel
              control={<Checkbox color="secondary"
                                 value={choice.id}
                                 {...register(`quiz.${question.qid}`, {
                                   required: true,
                                   validate: v => v.length && v.length > 0
                                 })} />
              }
              label={<span className="text-slate-700 text-xl">{choice.value}</span>}
              sx={{ml: 1}}
            />
          )
        }
      </FormGroup>
    }
    case TrueFalse: {
      return <FormGroup key={question.id} sx={{mb: 2}}>
        <FormLabel id={`${question.id}`}>
          <span className="text-2xl text-slate-700">{question.qid}. {question.question}</span>
        </FormLabel>
        <RadioGroup
          aria-labelledby={`${question.id}`}
          name={`${question.id}`}
        >
          <Grid item md={12}>
            <FormControlLabel value="t"
                              control={<Radio
                                color="secondary" {...register(`quiz.${question.qid}`, {required: true})}/>}
                              label={<span className="text-slate-700 text-xl">True</span>}
                              sx={{ml: 1}}
            />
          </Grid>
          <Grid item md={12}>
            <FormControlLabel value="f"
                              control={<Radio
                                color="secondary" {...register(`quiz.${question.qid}`, {required: true})}/>}
                              label={<span className="text-slate-700 text-xl">False</span>}
                              sx={{ml: 1}}
            />
          </Grid>
        </RadioGroup>
      </FormGroup>
    }
  }
};
const loadQuiz = (id) => {
  return new Promise((res, rej) => {
    import(`../state/quiz/${id}`)
      .then((data) => {
        res(data?.default);
      }).catch(e => rej(e));
  });
};
