const TrueFalse = "TF";
const MultipleChoice = "MC";

const GenerateTrueFalseQuestion = (id, question) => ({
    qid:id,
    type: TrueFalse,
    question
});

const GenerateMultipleChoiceQuestion = (id, question, choices) => ({
    qid:id,
    type: MultipleChoice,
    question,
    choices: choices.map((c, index) => ({id: "C" + (index + 1), value: c}))
});

module.exports = {
    TrueFalse, MultipleChoice, GenerateMultipleChoiceQuestion, GenerateTrueFalseQuestion
}