import Fuse from 'fuse.js';
import {useSelector} from "react-redux";

const useSearch = () => {
    const courses = useSelector(state => state.courses.available);
    const fuse = new Fuse(courses, {keys: ["name", "description"]});

    return (searchString) => fuse.search(searchString);
};

export default useSearch;