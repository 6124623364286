import {Button, FormControl, InputLabel, MenuItem, Select} from "@mui/material";
import * as React from "react";
import PaymentsRoundedIcon from "@mui/icons-material/PaymentsRounded";
import TimelapseRoundedIcon from "@mui/icons-material/TimelapseRounded";
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';
import RemoveShoppingCartIcon from '@mui/icons-material/RemoveShoppingCart';
import * as PropTypes from "prop-types";
import {getLocationByCode, getLocationDatesByCode} from "../util/FirstAidLocation";
import FirstAidOverview from "./FirstAidCourseOverview";

function Price({price}) {
  return <span className="text-lg font-bold text-slate-500 whitespace-pre-wrap mr-4">
            <PaymentsRoundedIcon fontSize="medium" className="mr-1 align-text-bottom"/>
            ${price}
          </span>;
}

Price.propTypes = {price: PropTypes.any};

const CreateCourseCardBuy = ({
                               course,
                               courseIsAddedToCart,
                               addRemoveFromCartHandler,
                               firstAidCourseDate,
                               firstAidCourseLocation,
                               setFirstAidCourseDate,
                               setFirstAidCourseLocation
                             }) => {
  const isFirstAidCourse = course.id === "FA" || course.id === "FAB";

  const firstAidHandler = (ev) => {
    addRemoveFromCartHandler(ev);
  }

  return <div
    className="flex flex-col max-w-xs sm:max-w-md justify-center mx-auto mt-10 mb-10 pb-3 rounded-lg shadow-md bg-indigo-100 border-gray-700">
    <img
      className="border-none max-w-xs sm:max-w-md place-self-center rounded-t rounded-l object-cover"
      src={"/static/img/courses/" + course.icon} alt="product image"/>
    <div className="flex flex-col justify-between my-auto px-3">
      <div className="flex flex-col py-3">
							<span className="font-semibold tracking-tight text-slate-700 break-normal">
								{course.name}
							</span>
        {!isFirstAidCourse &&
          <span className="text-base tracking-tight text-slate-600 break-normal">{course.description}</span>}
      </div>
      <div>
        <Price price={course.price}/>
        <span className="text-lg font-bold text-slate-500">
						<TimelapseRoundedIcon fontSize="medium" className="mr-1 align-text-bottom"/>
          {course.hours} Hrs
					 </span>
      </div>
      <div className="flex mt-4 flex-wrap gap-2 items-center py-1">
        {isFirstAidCourse && <>
          <FirstAidOverview/>
          <FormControl variant="standard" fullWidth className="py-4 pt-1">
            <InputLabel id={course.id + "_location_input"}>Location</InputLabel>
            <Select
              id={course.id + "_fa_location"}
              value={firstAidCourseLocation}
              label="Location"
              onChange={ev => {
                setFirstAidCourseLocation(ev.target.value)
              }
              }
            >
              <MenuItem value="s">{getLocationByCode("s")}</MenuItem>
            </Select>
          </FormControl>
          <FormControl variant="standard" fullWidth className="py-4">
            <InputLabel id={course.id + "_date_input"}>Date</InputLabel>
            <Select
              disabled={!firstAidCourseLocation}
              id={course.id + "_fa_date"}
              value={firstAidCourseDate}
              label="Date"
              onChange={ev => setFirstAidCourseDate(ev.target.value)}
            >
              {getLocationDatesByCode(firstAidCourseLocation).map(
                (date) => <MenuItem value={date.getTime()}>{formatDate(date)}</MenuItem>)
              }
            < /Select>
          </FormControl>
        </>
        }
        <Button onClick={isFirstAidCourse ? firstAidHandler : addRemoveFromCartHandler}
                startIcon={courseIsAddedToCart ? <RemoveShoppingCartIcon/> : <AddShoppingCartIcon/>}
                variant="contained"
                color="primary"
                disabled={isFirstAidCourse && (!firstAidCourseLocation || !firstAidCourseDate)}
                className="text-sm font-sans px-3 text-indigo-800"
        >
          <span>{courseIsAddedToCart ? "Remove" : "Add"}</span>
        </Button>
        {isFirstAidCourse &&
          <a href="/rescheduling.html" className="ml-auto text-sm text-blue-600">Cancellation & Rescheduling
            Policy</a>}
      </div>
    </div>
  </div>
};

export default CreateCourseCardBuy;

const DateOptions = {weekday: 'long', year: 'numeric', month: 'long', day: 'numeric'};

const formatDate = (date) => date.toLocaleDateString('en-US', DateOptions);
