import * as React from "react";
import {useState} from "react";
import ExpandCircleDownIcon from "@mui/icons-material/ExpandCircleDown";

const FirstAidOverview = () => {
  const [open, setOpen] = useState(false);
  const openClose = () => setOpen(open => !open);
  return <div className="flex flex-col">
    <div className="flex flex-row items-center cursor-pointer text-blue-600" onClick={openClose}>
      <ExpandCircleDownIcon fontSize="medium" className={"mr-1 " + (open ? "rotate-180" : "rotate-0")}/>
      <span className="font-bold text-lg">Overview</span>
    </div>
    {open && <div className="py-1">
      <p>
        This one-day course provides comprehensive training in first aid and cardiopulmonary resuscitation (CPR) skills,
        with a specific focus on addressing childhood injuries and illnesses. The curriculum covers the essential skills
        needed to recognize, prevent, and respond to circulation emergencies for infants and children.
      </p>
      <p className="py-1 pt-2">
        <span className="font-bold">Certification Type</span> Emergency First Aid Child & Residential Care (CPR C) by
        LifeSaving Society
      </p>
      <p className="py-1 pt-2">
        <span className="font-bold">Course Time</span> 9 AM to 5 PM
      </p>
      <p className="py-1 pt-2">
        <span className="font-bold">Key Topics Covered</span>
      </p>
      <ul className="list-disc px-6">
        <li>Recognize the signs and symptoms of life-threatening conditions</li>
        <li>Airway and breathing emergencies</li>
        <li>Circulation emergencies and CPR techniques</li>
        <li>Prevention of disease transmission</li>
        <li>Provide appropriate first aid for choking, bleeding, poison and other medical emergencies</li>
        <li>Common childhood injuries and illnesses</li>
      </ul>
      <p className="py-1 pt-2">
        <span className="font-bold">Who Should Attend</span></p>
      <p>
        This course is designed for individuals working in early childhood education, as well as parents, grandparents
        and caregivers who want to be prepared to handle pediatric emergencies.
      </p>
      <p className="py-1 pt-2">
        <span className="font-bold">Course Requirements and Certification</span>
      </p>
      <p>To successfully complete the course, participants must:
        <ul className="list-disc px-6">
          <li>Demonstrate proficiency in the required skills</li>
          <li>Attend the full course (100% attendance)</li>
          <li>Achieve a minimum score of 75% on the written test</li>
          <li>Upon completion, participants will receive a 3-Year Certification in Emergency Child Care CPR/AED Level
            B.
          </li>
        </ul>
      </p>
    </div>}
  </div>
}

export default FirstAidOverview;
