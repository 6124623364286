import {useEffect} from "react";
import {useLocation} from "react-router-dom";
import {useNavigate} from "react-router-dom";

const useDefaultRouter = () => {
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    // fix for react router bug 'no routes matched location _=_
    if (location.pathname === '_=_' && location.search === '') {
      navigate("/");
    }
  }, [location]);
};

export default useDefaultRouter;
