import {useSelector, useDispatch} from "react-redux";
import {AddNotification, ClearNotification, ClearNotifications} from "../NotificationState";

export const useGetNotification = () => {
    const dispatch = useDispatch();
    const notifications = useSelector(state => state.notification.notifications);
    const clearNotification = (notificationId) => dispatch(ClearNotification({id: notificationId}));

    // automatically clear notifications after 30 sec
    setTimeout(() => {
        notifications.forEach(n => clearNotification(n.id));
    }, 20 * 1000);

    return notifications;
}

export const useAddNotification = () => {
    const dispatch = useDispatch();
    return ({id, message}) => {
        dispatch(AddNotification({id, message}));
    };
}

export const useClearNotification = () => {
    const dispatch = useDispatch();
    return () => {
        dispatch(ClearNotifications());
    };
};