import { createTheme } from '@mui/material/styles';

let theme = createTheme({
    palette: {
        type: 'light',
        primary: {
            main: '#B8C0FF',
        },
        secondary: {
            main: '#5c9402',
        },
        background: {
            // default: '#f2f3fb',
        },
        text: {
            primary: '#2d2d34',
        },
    },
    typography: {
        fontFamily: 'Lato',
    },
});

export default {theme};
