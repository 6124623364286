import {combineReducers, configureStore} from '@reduxjs/toolkit';
import UserSliceReducer from "./UserState";
import CoursesReducer from './Courses';
import CartSliceReducer from "./Cart";
import NotificationSliceReducer from "./NotificationState";
import ApiSliceReducer from "./apiState";
import storageSession from 'redux-persist/lib/storage/session';
import {persistReducer, persistStore} from "redux-persist";

const persistedReducer = persistReducer({key: "root", storage: storageSession},
    combineReducers({
        user: UserSliceReducer,
        courses: CoursesReducer,
        cart: CartSliceReducer,
        notification: NotificationSliceReducer,
        api: ApiSliceReducer
    }));

const store = configureStore({
    reducer: persistedReducer,
    devTools: process.env.NODE_ENV !== 'production',
})

const persister = persistStore(store);

export {store, persister};