import DialogContent from "@mui/material/DialogContent";
import {Button, TextField} from "@mui/material";
import DialogActions from "@mui/material/DialogActions";
import React from "react";
import {styled} from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import {useDispatch, useSelector} from "react-redux";
import {useForm} from "react-hook-form";
import callApi from "../util/api";
import {useAddNotification} from "../state/hooks/notifications";
import {PatchUserDetails} from "../state/UserState";

const UserProfileDialog = () => {
    const userDetailsNeeded = useSelector(state => !state.user || !state.user.details || !state.user.details.name);
    const dispatch = useDispatch();
    const addNotification = useAddNotification();

    const updateUserDetails = async (userDetails) => {
        try {
            let userDetailsToUpdate = {
                name: userDetails.name,
                ece_expiry: parseDate(userDetails["ece-expiry"])
            };
            await callApi(dispatch, "patch", "/ece/user", userDetailsToUpdate);
            dispatch(PatchUserDetails(userDetailsToUpdate));
        } catch (err) {
            console.error("error updating user details", err);
            addNotification({
                id: "userDetails",
                message: "There was some problem updating your user details, please call Customer Support to proceed if the problem continues"
            });
        }
    };
    const {register, handleSubmit, formState} = useForm({mode: "onChange"});
    return <BootstrapDialog
        aria-labelledby="user-profile"
        open={userDetailsNeeded}
    >
        <form onSubmit={handleSubmit(updateUserDetails)}>
            <BootstrapDialogTitle id="user-profile">
                Please enter the following details for the course certificate
            </BootstrapDialogTitle>
            <DialogContent>
                <TextField id="name" label="Full name to be printed on your certificate" variant="outlined" fullWidth
                           margin="normal" {...register("name", {required: true})}/>
                <TextField id="eceExpiry"
                           inputProps={{
                               min: new Date().toISOString().slice(0, 10)
                           }}
                           InputLabelProps={{
                               shrink: true
                           }}
                           label="ECE License Expiry"
                           variant="outlined"
                           type="date"
                           fullWidth
                           {...register("ece-expiry")}/>
            </DialogContent>
            <DialogActions>
                <Button autoFocus variant="contained" type="submit"
                        disabled={!formState.isValid}>
                    Update
                </Button>
            </DialogActions>
        </form>
    </BootstrapDialog>
};

export default UserProfileDialog;

const BootstrapDialog = styled(Dialog)(({theme}) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));

const BootstrapDialogTitle = (props) => {
    const {children, onClose, ...other} = props;

    return (
        <DialogTitle sx={{m: 0, p: 2}} {...other}>
            {children}
        </DialogTitle>
    );
};

const parseDate = (yyyyMMdd) => {
    if (!yyyyMMdd) return null;
    const parts = yyyyMMdd.split("-").map(d => parseInt(d));
    return new Date(parts[0], parts[1] - 1, parts[2]).getTime();
}
