import {LoginButton} from "./comp/LoginButton";
import {
  CreditScoreRounded,
  FactCheckRounded,
  HeadphonesBatteryRounded,
  HistoryEduRounded,
  NotificationsActiveRounded,
  WorkspacePremiumRounded
} from "@mui/icons-material";
import {Link} from "react-router-dom";
import {Button} from "@mui/material";
import {useSelector} from "react-redux";
import NotificationToast from "./comp/NotificationToast";
import SchoolIcon from '@mui/icons-material/School';
import UpcomingIcon from '@mui/icons-material/Upcoming';
import PhoneInTalkRoundedIcon from '@mui/icons-material/PhoneInTalkRounded';
import AlternateEmailRoundedIcon from '@mui/icons-material/AlternateEmailRounded';

export default function Home() {
  const userLoggedIn = useSelector((state) => {
    return state.user.loggedIn
  });

  return (<>
      {userLoggedIn && <NotificationToast message="You're now logged in!"/>}
      <div className="overflow-hidden bg-contain sm:bg-cover bg-no-repeat text-center bg-mb-header sm:bg-d-header">
        <div
          className="flex justify-center items-center sm:pl-20 flex-col py-10 sm:flex-row bg-fixed bg-opacity-80 bg-gray-300">
          <main>
            <h1 className="text-3xl tracking-tight font-extrabold text-gray-900 sm:text-3xl md:text-3xl">
              <span
                className="block xl:inline text-slate-600 px-1">ECE Pro-D Courses & First Aid Training</span>
            </h1>
            <div className="py-6 flex flex-row justify-center items-center">
              <img className="w-32" src="static/img/LS-Logo.png"/>
              <span className="text-3xl font-semibold text-gray-600 px-4 font-serif">Certified Training Partner</span>
            </div>
            <div className="flex flex-col w-full">
              <span className="pt-4 text-lg italic text-gray-600 md:text-xl pr-1">
                    Our First Aid course meets the provincial child care licensing requirements (Schedule C).
                  </span>
              <span className="pt-4 text-lg italic text-gray-600 md:text-xl pr-1">
                    Our ECE Pro-D Courses meet the requirements from the BC ECE Registry requirements
                  </span>
              <span className="text-xl pt-20 font-medium tracking-tight text-slate-500 sm:text-2xl">Get certified from the comfort of your home, at your own pace</span>
              <span className="text-lg text-gray-600 md:text-xl pt-6 pr-1">
              <SchoolIcon fontSize="large" style={{color: "gray"}} className="mr-2 align-middle"/>
                    40 hours of Professional Development courses (including First Aid Training for <b>Greater
                    Vancouver, BC</b>)
                  </span>
              <span className="text-lg text-gray-600 md:text-xl pb-6 pr-1">
                <UpcomingIcon fontSize="large" style={{color: "gray"}} className="mr-2 align-middle"/>
                  More in-person First Aid classes will be scheduled soon!
              </span>
            </div>
            <div className="mt-20 my-auto">
              <LoginButton size="large"/>
              <Button
                className="underline"
                component={Link}
                to="courses"
                variant="button"
                color="text.primary"
                href="#"
              >
                Course Catalog
              </Button>
            </div>
            <div className="flex flex-col sm:flex-row space-y-1 sm:space-y-0 pt-10 justify-center items-center">
              <a href="https://wa.link/4qdykk"
                 className="hover:underline font-medium text-blue-700 inline-flex items-center">
                <img src="static/img/whatsapp-logo.webp" className="w-8"/> WhatsApp
              </a>
              <a href="tel:7782280880"
                 className="hover:underline font-medium text-blue-700 inline-flex items-center">
                <PhoneInTalkRoundedIcon className="fill-indigo-400 w-6 ml-3 mr-1"/>778 300 6341</a>
              <a href="mailto:support@learnece.online"
                 className="hover:underline font-medium text-blue-700 inline-flex items-center">
                <AlternateEmailRoundedIcon className="fill-indigo-400 w-8 ml-3"/> support@learnece.online
              </a>
            </div>
            <div className="text-2xl font-semibold font-serif text-gray-600 px-4 pt-10">
              Contact us to organize a private first aid workshop with as few as 3 participants, at a time and location
              of your choice.
            </div>
          </main>
        </div>
      </div>
      <div className="w-full mt-20 flex px-3 sm:px-6 lg:px-8 mb-7">
        <div className="mx-auto">
          <blockquote>
            <svg aria-hidden="true" className="w-10 h-10 text-gray-400" viewBox="0 0 24 27" fill="none"
                 xmlns="http://www.w3.org/2000/svg">
              <path
                d="M14.017 18L14.017 10.609C14.017 4.905 17.748 1.039 23 0L23.995 2.151C21.563 3.068 20 5.789 20 8H24V18H14.017ZM0 18V10.609C0 4.905 3.748 1.038 9 0L9.996 2.151C7.563 3.068 6 5.789 6 8H9.983L9.983 18L0 18Z"
                fill="currentColor"></path>
            </svg>
            <span
              className="italic text-slate-600 text-2xl">We make your ECE Licence renewal super easy!</span>
          </blockquote>
        </div>
      </div>
      <Features/>
    </>
  )
}

const features = [
  {
    name: 'ECE Registry approved courses',
    icon: FactCheckRounded,
  },
  {
    name: 'Downloadable course audio',
    icon: HeadphonesBatteryRounded,
  },
  {
    name: 'Secure payment through Paypal',
    icon: CreditScoreRounded,
  },
  {
    name: 'Downloadable certificates immediately available',
    icon: WorkspacePremiumRounded,
  },
  {
    name: 'Autofilled professional development section 2',
    icon: HistoryEduRounded,
  },
  {
    name: 'Certificate renewal email reminders',
    icon: NotificationsActiveRounded,
  }
]

function Features() {
  return (
    <div className="bg-transparent mb-20">
      <div className="mx-auto px-4 sm:px-6 lg:px-8">
        <div className="mt-1">
          <dl className="space-y-8 md:space-y-0 md:grid md:grid-cols-2 md:gap-x-20 md:gap-y-10 pb-10">
            {features.map((feature) => (
              <div key={feature.name} className="flex align-baseline bg-indigo-800 rounded-lg shadow-sm">
                <div className="flex flex-row">
                  <div
                    className="ml-3 p-2 rounded-l-md text-gray-200">
                    <feature.icon className="h-14 w-14 my-3 mx-1" aria-hidden="true"/>
                  </div>
                  <span className="my-auto text-xl font-medium text-gray-200">{feature.name}</span>
                </div>
              </div>
            ))}
          </dl>
        </div>
      </div>
    </div>
  )
}
