import { createSlice } from '@reduxjs/toolkit'

/**
 * Notification schema = {
 *     id, message
 * }
 */
export const notificationSlice = createSlice({
    name: 'Notification',
    initialState: {
        notifications: []
    },
    reducers: {
        AddNotification: (state, action) => {
            state.notifications.push(action.payload);
        },
        ClearNotification: (state, action) => {
            state.notifications = state.notifications.filter(n => n.id !== action.payload.id);
        },
        ClearNotifications: (state, action) => {
            state.notifications = [];
        }
    }
})

export const { AddNotification, ClearNotification, ClearNotifications } = notificationSlice.actions
export default notificationSlice.reducer;