import {createSlice} from '@reduxjs/toolkit'

export const CartSlice = createSlice({
  name: 'Cart',
  initialState: {
    courses: {},
    token: {},
    firstAidCourseDate: null
  },
  reducers: {
    AddCourse: (state, action) => {
      const course = action.payload;
      state.courses[`${course.id}`] = course;
    },
    RemoveCourse: (state, action) => {
      delete state.courses[action.payload.id];
      if (action.payload.id === "FA" || action.payload.id === "FAB") {
        state.firstAidCourseDate = null;
        state.firstAidCourseLocation = null;
      }
    },
    SetCartToken: (state, action) => {
      state.token = action.payload.token;
    },
    ClearCart: (state) => {
      state.courses = {};
    },
    SetFirstAidCourseDate: (state, action) => {
      state.firstAidCourseDate = action.payload.date;
    },
    SetFirstAidCourseLocation: (state, action) => {
      state.firstAidCourseLocation = action.payload.location;
    }
  }
})

// Action creators are generated for each case reducer function
export const {
  AddCourse,
  RemoveCourse,
  SetCartToken,
  ClearCart,
  SetFirstAidCourseDate,
  SetFirstAidCourseLocation
} = CartSlice.actions

export default CartSlice.reducer;
