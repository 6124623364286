import Button from "@mui/material/Button";
import * as React from "react";
import {useState} from "react";
import LoginDialog from "./LoginDialog";
import {Auth} from 'aws-amplify';
import {useDispatch, useSelector} from "react-redux";
import {LoggedOut} from '../state/UserState';

export const LoginButton = ({size = "medium"}) => {
    const dispatch = useDispatch();
    const userLoggedIn = useSelector((state) => {
        return state.user.loggedIn
    });
    const [showLogin, setShowLogin] = useState(false);
    const onClick = async () => {
        if (userLoggedIn) {
            try {
                await Auth.signOut({global: true});
                dispatch(LoggedOut())
                return;
            }
            catch(err){
                console.error('error signing out user', err);
                dispatch(LoggedOut())
                return;
            }
        }
        setShowLogin(true);
    };
    return <>
      <Button className="bg-indigo-800 hover:bg-indigo-600 text-gray-100" size={size} variant="contained"
              sx={{my: 1, mx: 1.5}} onClick={onClick}>
            {userLoggedIn ? "Logout" : "Sign Up / Login"}
        </Button>
        <LoginDialog isVisible={showLogin} isVisibleCallback={setShowLogin}/>
    </>
}
