import {createSlice} from '@reduxjs/toolkit'

export const userSlice = createSlice({
  name: 'User',
  initialState: {
    loggedIn: false
  },
  reducers: {
    LoggedIn: (state) => {
      state.loggedIn = true;
    },
    LoggedOut: (state) => {
      state.loggedIn = false;
      state.details = null;
    },
    SetUserDetails: (state, action) => {
      delete state.details;
      state.details = action.payload;
    },
    PatchUserDetails: (state, action) => {
      state.details.name = action.payload.name;
      state.details.ece_expiry = action.payload.ece_expiry;
    },
    AddEnrolled: (state, action) => {
      state.details.enrolled = state.details.enrolled || [];
      state.details.enrolled = state.details.enrolled.concat(action.payload.enrolled);
    },
    SetQuizResult: (state, action) => {
      state.details[`${action.payload.courseId}_quiz_passed`] = action.payload.result;
      state.details[`${action.payload.courseId}_quiz_date`] = new Date().getTime();
    },
    SetSection2InProgress: (state, action) => {
      state.details.section2 = {s: "in_progress", uri: null};
    },
    WipeOutSection2: (state, action) => {
      state.details.section2 = {};
    }
  }
})

// Action creators are generated for each case reducer function
export const {LoggedIn, LoggedOut, SetUserDetails, SetQuizResult, AddEnrolled, PatchUserDetails, SetSection2InProgress, WipeOutSection2} = userSlice.actions

export default userSlice.reducer;
