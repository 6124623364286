import {Auth} from "aws-amplify";

const awsmobile = {
    "aws_project_region": "us-west-2",
    "aws_cognito_identity_pool_id": "us-west-2:755c5cb0-cc6e-461e-835c-9636e886dee1",
    "aws_cognito_region": "us-west-2",
    "aws_user_pools_id": "us-west-2_FxoRRuaqn",
    "aws_user_pools_web_client_id": "48pdq22pd535miius8r2auh8cl",
    "oauth": {
        "domain": "auth.learnece.online",
        "scope": [
            "email",
            "openid",
            "profile"
        ],
        "redirectSignIn": "https://learnece.online/",
        "redirectSignOut": "https://learnece.online/",
        "responseType": "code"
    },
    "federationTarget": "COGNITO_USER_AND_IDENTITY_POOLS",
    "aws_user_files_s3_bucket_region": "us-west-2",
    "aws_dynamodb_all_tables_region": "us-west-2",
    "aws_cloud_logic_custom": [
        {
            "name": "ece-dev",
            "endpoint": "https://03yt7co4a2.execute-api.us-west-2.amazonaws.com/ece-prod",
            "region": "us-west-2",
            custom_header: async () => {
                return {idtoken: `${await getIdToken()}`}
            }
        }
    ]
};
export default awsmobile;

const getIdToken = async () => {
    try {
        const user = await Auth.currentSession();
        if (!user) return;
        return user.getIdToken().getJwtToken();
    } catch (err) {
    }
}