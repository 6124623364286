import {End, Start, Error} from "../state/apiState";
import {API} from "aws-amplify";

const callApi = (dispatch, method, url, body) => {
    dispatch(Start({url, method}));
    return new Promise((res, rej) => {
        API[method]("ece-dev", url, {body})
            .then(data => {
                dispatch(End());
                res(data);
            }).catch(error => {
            dispatch(Error({error}));
            rej(error);
        });
    });
};

export default callApi;