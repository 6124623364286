import {useDispatch, useSelector} from "react-redux";
import {Button, Grid} from "@mui/material";
import * as React from "react";
import {useState} from "react";
import LoginDialog from "../comp/LoginDialog";
import AvailableCourses from "../state/AvailableCourses";
import {useNavigate} from "react-router-dom";
import PublishedWithChangesIcon from '@mui/icons-material/PublishedWithChanges';
import callApi from "../util/api";
import useRefreshUserDetailsForPendingSection2 from "../hooks/useRefreshUserDetailsForPendingSection2";
import {SetSection2InProgress} from "../state/UserState";
import CourseCardEnrolled from "../comp/CourseCardEnrolled";

const MyCourses = () => {
  const navigate = useNavigate();
  const userLoggedIn = useSelector((state) => {
    return state.user.loggedIn
  });
  const userDetails = useSelector((state) => {
    return state.user.details;
  });

  const section2Available = userDetails
    && userDetails.section2
    && userDetails.section2.uri
    && userDetails.section2.s === 'completed';
  const section2InProgress = userDetails
    && userDetails.section2
    && userDetails.section2.s === 'in_progress';

  const dispatch = useDispatch();
  const refreshUser = useRefreshUserDetailsForPendingSection2();

  const callGenerateSection2 = async () => {
    await callApi(dispatch, "post", "/ece/section2", null);
    dispatch(SetSection2InProgress());
    await refreshUser();
  }

  const hasPassedAtLeastOneCourse = userDetails && ((userDetails.enrolled || [])
    .map(course => userDetails[course.id + "_quiz_passed"])
    .filter(passed => passed)
    .length > 0);
  // console.log('user', JSON.stringify(user));
  const [showLogin, setShowLogin] = useState(!userLoggedIn);
  const enrolled = userDetails && userDetails.enrolled ? userDetails.enrolled : [];
  const enrolledCourseDetails = enrolled
    .filter(enrolledCourse => enrolledCourse.id !== "BUN") // don't display ProD bundle
    .map(enrolledCourse => ({
      available: AvailableCourses.find(course => course.id == enrolledCourse.id),
      enrolled: enrolledCourse
    }))
    .filter(c => c.available)
    .map(c => ({...c.available, ...c.enrolled}));
  // console.log('enrolled', JSON.stringify(enrolledCourseDetails));
  // console.log("available", JSON.stringify(AvailableCourses));

  const courses = enrolledCourseDetails.map(course =>
    CourseCardEnrolled({
      course,
      quizPassed: userDetails[`${course.id}_quiz_passed`],
      quizDate: userDetails[`${course.id}_quiz_date`],
      quizCertificateUrl: userDetails[`${course.id}_quiz_cert_uri`]
    }));
  return <>
    <Grid marginTop={2} marginBottom={10} item container spacing={2} flexDirection="column">
      {userLoggedIn && hasPassedAtLeastOneCourse && <div className="mx-auto">
        {getSection2Button(section2InProgress, callGenerateSection2, section2Available, userDetails.section2)}
      </div>
      }
      {userLoggedIn && courses}
      {userLoggedIn && !enrolledCourseDetails.length && <div style={{textAlign: "center", width: "100%"}}>
        <Button
          variant="contained"
          color="primary"
          style={{textAlign: "center", margin: "30px"}}
          onClick={() => navigate("/courses")}>
          Browse courses to purchase
        </Button>
      </div>
      }
      {!userLoggedIn &&
        <div style={{alignItems: "center", margin: "auto"}}>
          <Button
            variant="contained"
            color="primary"
            style={{textAlign: "center", margin: "30px"}}
            onClick={() => setShowLogin(true)}>
            Login to continue
          </Button>
        </div>
      }
    </Grid>
    <LoginDialog isVisible={showLogin} isVisibleCallback={setShowLogin}/>
  </>
};

export default MyCourses;

function getSection2Button(section2InProgress, callGenerateSection2, section2Available, section2 = {}) {
  return section2Available
    ? <Button
      variant="contained"
      color="primary"
      href={section2.uri}
      target="_blank"
      style={{textAlign: "center", marginTop: "30px"}}
      startIcon={<PublishedWithChangesIcon/>}
    >
      <span>Download Section 2 ECE Licence Renewal</span>
    </Button>
    : <Button
      variant="contained"
      color="primary"
      disabled={section2InProgress}
      style={{textAlign: "center", marginTop: "30px"}}
      onClick={callGenerateSection2}
      startIcon={<PublishedWithChangesIcon/>}
    >
      <span className="text-sm">{section2InProgress ? "Generating Pro D Section 2" : "Generate Pro D Section 2"}</span>
    </Button>;
}
