const LocationDetails = [
  {
    code: "s",
    location: "Math4Me, 201-14377 64 Ave, Surrey, BC",
    dates: [
      new Date(2024, 7, 11),
      new Date(2024, 7, 18),
      new Date(2024, 7, 25),
      new Date(2024, 8, 1),
      new Date(2024, 8, 8),
      new Date(2024, 8, 15),
      new Date(2024, 8, 22),
      new Date(2024, 8, 29)
    ]
  }
];
const LocationCodeMap = {};
LocationDetails.forEach(l => LocationCodeMap[l.code] = l.location);
const LocationDatesMap = {};
LocationDetails.forEach(l => LocationDatesMap[l.code] = l.dates);

const getLocationByCode = (code = "s") => code ? LocationCodeMap[code] : LocationCodeMap["s"];
const getLocationDatesByCode = (code = "s") => code ? LocationDatesMap[code] : LocationDatesMap["s"];

export {LocationDetails, getLocationByCode, getLocationDatesByCode};
