import './config/Aws';
import './App.scss';
import {
  AppBar,
  CssBaseline,
  GlobalStyles,
  Link as MaterialUiLink,
  StyledEngineProvider,
  ThemeProvider
} from "@mui/material";
import theme from './theme/Theme';
import {LoginButton} from "./comp/LoginButton";
import {Link, Route, Routes} from "react-router-dom";
import Courses from "./container/Courses";
import ShoppingCartTwoToneIcon from '@mui/icons-material/ShoppingCartTwoTone';
import MyCourses from "./container/MyCourses";
import Quiz from "./comp/Quiz";
import {useGetNotification} from "./state/hooks/notifications";
import Cart from "./container/Cart";
import Home from "./Home";
import FirstAidEnrollment from "./container/FirstAidEnrollment";
import {Auth} from "aws-amplify";
import {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import AutoStoriesIcon from '@mui/icons-material/AutoStories';
import LaptopChromebookIcon from '@mui/icons-material/LaptopChromebook';

import NotificationToast from "./comp/NotificationToast";
import useDefaultRouter from "./util/DebugRouter";
import Footer from "./Footer";

function App() {
  const [userIsAdmin, setUserIsAdmin] = useState(false);
  const notifications = useGetNotification();
  const userLoggedIn = useSelector((state) => {
    return state.user.loggedIn
  });

  useDefaultRouter();

  useEffect(async () => {
    if (userLoggedIn) {
      const userIsAdmin = await isUserAdmin();
      setUserIsAdmin(userIsAdmin);
    }
  }, [userLoggedIn]);

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme.theme}>
        <GlobalStyles styles={{ul: {margin: 0, padding: 0, listStyle: 'none'}}}/>
        <CssBaseline/>
        <AppBar
          position="static"
          color="transparent"
          elevation={0}
        >
          <NavBar userIsAdmin={userIsAdmin}/>
          {notifications.map(n => <NotificationToast message={n.message} key={n.id}/>)}
        </AppBar>
        <Routes>
          <Route index element={<Home/>}/>
          <Route path="/" element={<Home/>}/>
          <Route path="_=_" element={<Home/>}/>
          <Route path="/courses" element={<Courses/>}/>
          <Route path="/mycourses" element={<MyCourses/>}/>
          <Route path="/quiz/:id" element={<Quiz/>}/>
          <Route path="/cart" element={<Cart/>}/>
          <Route path="/fa" element={<FirstAidEnrollment/>}/>
        </Routes>
        <Footer/>
      </ThemeProvider>
    </StyledEngineProvider>
  );
}

export default App;

const isUserAdmin = async () => {
  const user = await Auth.currentAuthenticatedUser();
  const groups = user.getSignInUserSession().accessToken.payload['cognito:groups'] || [];
  return groups.includes("admin");
};

const NavBar = ({userIsAdmin}) => {
  const [isOpen, setIsOpen] = useState(false);
  const toggleOpen = () => {
    // console.log('toggle', isOpen)
    setIsOpen(!isOpen);
  }

  return <nav>
    <div className="container flex flex-wrap justify-between align-middle items-center mx-auto">
      <a href="https://learnece.online/" className="flex">
        <img src="static/img/learn-ece-logo.png"
             style={{
               objectFit: "cover",
               minWidth: "280px",
               maxWidth: "400px",
               marginTop: "10px",
               marginBottom: "10px"
             }}
        />
      </a>
      <button onClick={toggleOpen}
              type="button"
              className="inline-flex items-center p-2 ml-3 mr-1 text-sm text-gray-500 rounded-lg md:hidden hover:bg-gray-100 focus:outline-none focus:ring-gray-200"
              aria-controls="navbar-default" aria-expanded="false">
        <span className="sr-only">Open main menu</span>
        <svg className="w-6 h-6" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20"
             xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd"
                d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
                clip-rule="evenodd"></path>
        </svg>
      </button>
      <div className={"w-full md:block md:w-auto " + (isOpen ? "" : "hidden")} id="navbar-default">
        <ul
          className="flex flex-col p-4 mt-4 md:flex-row md:items-center md:space-x-8 md:mt-0 md:text-sm md:font-medium md:border-0">
          <li>
            {userIsAdmin && (<MaterialUiLink
              component={Link}
              style={{textDecoration: 'none'}}
              to="fa"
              variant="button"
              color="text.primary"
              href="#"
              className="block py-2 pr-4 pl-3 text-gray-700 rounded hover:bg-transparent border-0 hover:text-blue-700 md:p-0"
            >
              First Aid Enrollments
            </MaterialUiLink>)
            }
          </li>
          <li>
            <MaterialUiLink
              component={Link}
              style={{textDecoration: 'none'}}
              to="courses"
              variant="button"
              color="text.primary"
              href="#"
              className="py-2 pr-4 pl-3 text-gray-700 rounded hover:bg-transparent border-0 hover:text-blue-700 md:p-0"
            >
              <AutoStoriesIcon style={{verticalAlign: "middle", marginBottom: "4", marginRight: "5"}} fontSize="small"/>
              <span className="text-slate-700 font-semibold"> Course Catalog</span>
            </MaterialUiLink>
          </li>
          <li>
            <MaterialUiLink
              component={Link}
              style={{textDecoration: 'none'}}
              to="mycourses"
              variant="button"
              color="text.primary"
              href="#"
              className="block py-2 pr-4 pl-3 text-gray-700 rounded hover:bg-transparent border-0 hover:text-blue-700 md:p-0"
            >
              <LaptopChromebookIcon style={{verticalAlign: "middle", marginBottom: "4", marginRight: "5"}}
                                    fontSize="small"/>
              <span className=" font-semibold text-slate-700"> My Courses</span>
            </MaterialUiLink>
          </li>
          <li>
            <MaterialUiLink
              component={Link}
              style={{textDecoration: 'none'}}
              to="cart"
              variant="button"
              color="text.primary"
              href="#"
              className="block py-2 pr-4 pl-3 text-gray-700 rounded hover:bg-transparent border-0 hover:text-blue-700 md:p-0"
            >
              <ShoppingCartTwoToneIcon style={{verticalAlign: "middle", marginBottom: "4", marginRight: "5"}}
                                       fontSize="small"/>
              <span className=" font-semibold text-slate-700">Cart</span>
            </MaterialUiLink>
          </li>
          <li>
            <LoginButton
              className="block rounded md:border-0"/>
          </li>
        </ul>
      </div>
    </div>
  </nav>;
}
