import { createSlice } from '@reduxjs/toolkit'
import AvailableCourses from "./AvailableCourses";

export const coursesSlice = createSlice({
    name: 'courses',
    available: [],
    initialState: {
        available: AvailableCourses
    }
})

// Action creators are generated for each case reducer function
export default coursesSlice.reducer;