import * as React from 'react';
import {useState} from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import {Button, Grid} from "@mui/material";
import LoginDialog from "./LoginDialog";
import CancelRoundedIcon from '@mui/icons-material/CancelRounded';
import {useNavigate} from "react-router-dom";
import BrainTree from "./BrainTree";

const TAX_RATE = 0.05;

function ccyFormat(num) {
  return `${num.toFixed(2)}`;
}

function subtotal(items) {
  return items.map(({price}) => price).reduce((sum, i) => sum + i, 0);
}

export default function CartItems({
                                    isPromotion = false,
                                    courses,
                                    userLoggedIn,
                                    enrolled,
                                    removeFromCartHandler,
                                    token,
                                    paymentSubmitHandler,
                                    selectedFirstAidCourseDate,
                                    selectedFirstAidCourseLocation
                                  }) {
  const [showLogin, setShowLogin] = useState(false);
  const invoiceSubtotal = subtotal(courses);
  const navigate = useNavigate();
  const invoiceTaxes = TAX_RATE * invoiceSubtotal;
  const invoiceTotal = isPromotion ? 0 : invoiceTaxes + invoiceSubtotal;
  const isCartEmpty = !courses.length;
  const promotionCourse = {
    id: "promotion",
    name: "Promotional Discount",
    price: -invoiceSubtotal
  };

  if (isCartEmpty) {
    return <Grid marginTop={2} item container spacing={2} flexDirection="column">
      <div style={{textAlign: "center", width: "100%"}}>
        <Button
          variant="contained"
          color="primary"
          className="bg-indigo-800 hover:bg-indigo-600 text-gray-100"
          style={{textAlign: "center", margin: "30px"}}
          onClick={() => navigate("/courses")}>
          Browse courses to purchase
        </Button>
      </div>
    </Grid>
  }

  return (<>
      <TableContainer component={Paper} sx={{alignItems: "center"}}>
        <Table sx={{minWidth: 400, margin: "auto", maxWidth: 800, marginBottom: 15}} aria-label="spanning table">
          <TableHead>
            <TableRow>
              <TableCell align="left" colSpan={2} style={{borderBottom: "none"}}>
              </TableCell>
              <TableCell colSpan={1} style={{borderBottom: "none"}}></TableCell>
              <TableCell align="right" style={{borderBottom: "none"}}>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {courses.map((course) => renderCourse(course, removeFromCartHandler, selectedFirstAidCourseDate, selectedFirstAidCourseLocation, enrolled))}
            {isPromotion && renderPromotionCourse(promotionCourse)}
            <TableRow className="text-slate-700">
              <TableCell rowSpan={3} style={{borderBottom: "none"}}/>
              <TableCell style={{borderBottom: "none"}}></TableCell>
              <TableCell style={{borderBottom: "none"}}>Subtotal</TableCell>
              <TableCell align="right"
                         style={{borderBottom: "none"}}>${ccyFormat(isPromotion ? 0 : invoiceSubtotal)}</TableCell>
            </TableRow>
            <TableRow className="text-slate-700">
              <TableCell style={{borderBottom: "none"}}></TableCell>
              <TableCell
                style={{borderBottom: "none"}}>{`${(TAX_RATE * 100).toFixed(0)}%`} GST</TableCell>
              <TableCell align="right"
                         style={{borderBottom: "none"}}>${ccyFormat(isPromotion ? 0 : invoiceTaxes)}</TableCell>
            </TableRow>
            <TableRow className="text-slate-700">
              <TableCell style={{borderBottom: "none"}}></TableCell>
              <TableCell style={{borderBottom: "none"}}><span
                className={"text-lg text-slate-700 "}>Total (CAD)</span></TableCell>
              <TableCell align="right"
                         style={{borderBottom: "none"}}><span
                className={"text-lg text-slate-700 font-bold"}>${ccyFormat(invoiceTotal)}</span></TableCell>
              {isPromotion}
            </TableRow>
            <TableRow className="text-slate-700">
              <TableCell colSpan={3} align="center">
                {!userLoggedIn &&
                  <h3><Button variant="contained"
                              className="bg-indigo-800 hover:bg-indigo-600 text-gray-100"
                              color="primary"
                              onClick={() => setShowLogin(true)}>
                    Login to purchase
                  </Button>
                  </h3>}
                {userLoggedIn && !isPromotion && <BrainTree
                  token={token}
                  paymentSubmitHandler={paymentSubmitHandler}
                  userLoggedIn={userLoggedIn}
                />}
                {isPromotion && userLoggedIn && <button
                  onClick={async () => await paymentSubmitHandler(null)}
                  className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-full">Purchase</button>}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
      <LoginDialog isVisible={showLogin} isVisibleCallback={setShowLogin}/>
    </>
  );
}

function renderCourse(course, removeFromCartHandler, selectedFirstAidCourseDate, selectedFirstAidCourseLocation, enrolled) {
  return <>
    <TableRow key={course.id}>
      <TableCell colSpan={2} style={{borderBottom: "none"}}>
        <div className="w-full">
          <Button onClick={removeFromCartHandler(course.id)}
                  size="small"
                  variant="contained"
                  className="align-middle bg-gray-200 hover:bg-gray-400 text-slate-700"
                  startIcon={<CancelRoundedIcon/>}>Remove</Button>
          {
            course.id === "FA" || course.id === "FAB"
              ? <span
                className="ml-2 text-slate-500 text-lg align-middle">{course.name + " on " + new Date(selectedFirstAidCourseDate).toDateString() + ", at " + selectedFirstAidCourseLocation}</span>
              : <span
                className="ml-2 text-slate-500 text-lg  align-middle">{course.name}</span>
          }
        </div>
        <div className="text-center w-full">
          {enrolled[course.id] ?
            <i>purchased on {new Date(enrolled[course.id].t).toDateString()}</i> : null}
        </div>
      </TableCell>
      <TableCell colSpan={1} style={{borderBottom: "none"}}>
      </TableCell>
      <TableCell align="right"
                 style={{borderBottom: "none"}}>
        <span className="font-bold">${ccyFormat(course.price)}</span></TableCell>
    </TableRow>
  </>;
}

function renderPromotionCourse(course) {
  return <>
    <TableRow key={course.id}>
      <TableCell colSpan={2} style={{borderBottom: "none"}}>
        <div className="w-full">
          <span
            className="ml-2 text-slate-500 text-lg  align-middle">{course.name}</span>
        </div>
      </TableCell>
      <TableCell colSpan={1} style={{borderBottom: "none"}}>
      </TableCell>
      <TableCell align="right"
                 style={{borderBottom: "none"}}>
        <span className="font-bold">${ccyFormat(course.price)}</span></TableCell>
    </TableRow>
  </>;
}
