import {createSlice} from '@reduxjs/toolkit'

export const ApiSlice = createSlice({
    name: 'Api',
    initialState: {
        url: null,
        method: null,
        loading: false,
        success: false,
        error: null
    },
    reducers: {
        Start: (state, action) => {
            state.url = action.payload.url;
            state.method = action.payload.method;
            state.loading = true;
            state.success = false;
            state.error = null;
        },
        End: (state, action) => {
            state.loading = false;
            state.success = true;
        },
        Error: (state, action) => {
            state.loading = false;
            state.success = false;
            state.error = action.payload.error;
        }
    }
})

// Action creators are generated for each case reducer function
export const {Start, End, Error} = ApiSlice.actions

export default ApiSlice.reducer;