import {Auth} from "aws-amplify";

const awsmobile = {
    "aws_project_region": "us-west-2",
    "aws_cognito_identity_pool_id": "us-west-2:d2a5e351-93b5-4e6a-9c67-8b6ebb02d990",
    "aws_cognito_region": "us-west-2",
    "aws_user_pools_id": "us-west-2_76xPvecc6",
    "aws_user_pools_web_client_id": "79sh8css3qes44tmqtuv2qh72c",
    "oauth": {
        "domain": "ece-dev-workshop.auth.us-west-2.amazoncognito.com",
        "scope": [
            "email",
            "openid",
            "profile"
        ],
        "redirectSignIn": "http://localhost:3000/",
        "redirectSignOut": "http://localhost:3000/",
        "responseType": "code"
    },
    "federationTarget": "COGNITO_USER_AND_IDENTITY_POOLS",
    "aws_user_files_s3_bucket": "ece-dev192642-dev",
    "aws_user_files_s3_bucket_region": "us-west-2",
    "aws_dynamodb_all_tables_region": "us-west-2",
    "aws_dynamodb_table_schemas": [
        {
            "tableName": "ece-workshop",
            "region": "us-west-2"
        }
    ],
    "aws_cloud_logic_custom": [
        {
            "name": "ece-dev",
            "endpoint": "https://8wzv1vbr7k.execute-api.us-west-2.amazonaws.com/ece-dev",
            "region": "us-west-2",
            custom_header: async () => {
                return {idtoken: `${await getIdToken()}`}
            }
        }
    ]
};
export default awsmobile;

const getIdToken = async () => {
    try {
        const user = await Auth.currentSession();
        if (!user) return;
        return user.getIdToken().getJwtToken();
    } catch (err) {
    }
}