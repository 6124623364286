import * as React from 'react';
import {useEffect} from 'react';
import Button from '@mui/material/Button';
import {styled} from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import {CognitoHostedUIIdentityProvider} from '@aws-amplify/auth';
import {useForm} from "react-hook-form";
import {Backdrop, CircularProgress, TextField} from "@mui/material";
import {API, Auth, Hub} from 'aws-amplify';
import {useDispatch, useSelector} from 'react-redux';
import {LoggedIn, LoggedOut, SetUserDetails} from '../state/UserState';
import {SetCartToken} from "../state/Cart";
import {useAddNotification, useClearNotification} from "../state/hooks/notifications";
import {End, Error, Start} from "../state/apiState";
import FacebookIcon from '@mui/icons-material/Facebook';
import GoogleIcon from '@mui/icons-material/Google';

const LoginDialog = ({isVisible, isVisibleCallback}) => {
  const dispatch = useDispatch();
  const addNotification = useAddNotification();
  const clearNotifications = useClearNotification();

  const {register, handleSubmit} = useForm();
  const [open, setOpen] = React.useState(false);

  useEffect(() => {
    setOpen(isVisible);
  }, [isVisible]);

  const federatedSignIn = async (provider) => {
    dispatch(Start({url: "/federatedSignIn", method: "post"}));
    await Auth.federatedSignIn({provider: provider});
    dispatch(End());
  }

  useEffect(() => {
    return Hub.listen("auth", async ({payload: {event, data}}) => {
      switch (event) {
        case "signIn": {
          try {
            dispatch(Start({url: "/ece/user", method: "get"}));
            dispatch(LoggedIn());
            const user = await API.get("ece-dev", "/ece/user", null);
            dispatch(SetUserDetails(user[0]));
            const token = await API.get("ece-dev", "/ece/cart/token", null);
            dispatch(SetCartToken({token: token.clientToken}));
            dispatch(End());
            break;
          } catch (err) {
            dispatch(Error({error: err}));
            addNotification({
              id: "login",
              message: "Sorry, we ran into some network issue while trying to get your user details, please try again."
            })
          }
        }
        case "signOut":
          dispatch(LoggedOut());
          dispatch(SetCartToken({token: null}));
          clearNotifications();
          break;
      }
    });
  }, []);

  const onSubmit = async (loginDetails) => {
    try {
      await Auth.signIn(loginDetails.email, loginDetails.password);
      setOpen(false);
      isVisibleCallback(false);
    } catch (err) {
      console.log('error signing in', err);
    }
  };

  const handleClose = () => {
    setOpen(false);
    isVisibleCallback(false);
  };
  const apiLoading = useSelector(state => state.api.loading);

  return <>
    <BootstrapDialog
      onClose={handleClose}
      aria-labelledby="login"
      open={open}
    >
      <Button
        className="uppercase mt-8 w-1/2 mb-4 font-bold rounded-md m-auto bg-blue-500 hover:bg-blue-700 py-1 pb-2 text-slate-600 drop-shadow-lg"
        onClick={() => federatedSignIn(CognitoHostedUIIdentityProvider.Facebook)}>
        <FacebookIcon fontSize="small" sx={{color: "lightgray"}}/>
        <span className="ml-2 align-middle text-gray-100 text-sm">Login with Facebook</span>
      </Button>
      <Button
        className="uppercase w-1/2 font-bold rounded-md bg-blue-500 hover:bg-blue-700 m-auto py-1 pb-2 text-slate-600 drop-shadow-lg"
        onClick={() => federatedSignIn(CognitoHostedUIIdentityProvider.Google)}>
        <GoogleIcon fontSize="small" sx={{color: "lightgray"}}/>
        <span className="ml-2 align-middle text-gray-100 text-sm">Login with Google</span>
      </Button>
      <div className="w-full p-3 pt-4">
        <span className="text-sm text-slate-500">Please always use the same sign-in method to avoid creating more than one user account</span>
      </div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}/>
        <DialogContent>
          <TextField id="email" label="Email" variant="outlined" fullWidth
                     margin="normal" {...register("email", {required: true})}/>
          <TextField id="password" label="Password" variant="outlined" type="password"
                     fullWidth {...register("password", {required: true})}/>
        </DialogContent>
        <DialogActions>
          <Button autoFocus className="bg-indigo-800 hover:bg-indigo-600 " variant="contained" color="info"
                  type="submit">
            Log In With Email
          </Button>
        </DialogActions>
      </form>
    </BootstrapDialog>
    <Backdrop
      sx={{color: '#ffffff', zIndex: (theme) => theme.zIndex.drawer + 1}}
      open={apiLoading}
    >
      <CircularProgress color="inherit"/>
    </Backdrop>
  </>
}

export default LoginDialog;

const BootstrapDialog = styled(Dialog)(({theme}) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

const BootstrapDialogTitle = (props) => {
  const {children, onClose, ...other} = props;

  return (
    <DialogTitle sx={{m: 0, p: 2}} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon/>
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};
