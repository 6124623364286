import {useCallback, useState} from "react";
import {useDispatch} from "react-redux";
import {API} from "aws-amplify";
import {SetUserDetails} from "../state/UserState";

const MAX_TRIES = 10;
const POLL_INTERVAL_MSEC = 5000;

const useRefreshUserDetailsWhenPendingSection2 = () => {
  const dispatch = useDispatch();
  const [attempt, setAttempt] = useState(0);
  return useCallback(async () => {
    let timer = setInterval(() => {
      if (attempt >= MAX_TRIES) {
        clearInterval(timer);
        return;
      }

      API.get("ece-dev", "/ece/user", null)
        .then((user) => {
          const userRecord = user[0];
          if (userRecord
            && userRecord.section2
            && userRecord.section2.uri
            && userRecord.section2.s === "completed") {
            dispatch(SetUserDetails(userRecord));
            clearInterval(timer);
          }
        });

      setAttempt(attempt + 1);
    }, POLL_INTERVAL_MSEC);
  }, []);
};

export default useRefreshUserDetailsWhenPendingSection2;
